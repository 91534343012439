import { fromJS } from "immutable"
import { USER_LOGGED_IN, LOGOUT } from "./constants"
import queryString from "query-string"

const { authToken, authEmail } = queryString.parse(window.location.search)

const INITIAL_STATE = fromJS({
  token:
    authToken ||
    window.localStorage.getItem("authToken") ||
    window.localStorage.getItem("__authToken"),
  email:
    authEmail ||
    window.localStorage.getItem("authEmail") ||
    window.localStorage.getItem("__authEmail")
})

if (authToken) {
  window.localStorage.setItem("authToken", authToken)
  window.localStorage.setItem("__authToken", authToken)
}
if (authEmail) {
  window.localStorage.setItem("authEmail", authEmail)
  window.localStorage.setItem("__authEmail", authEmail)
}

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case USER_LOGGED_IN: {
      window.localStorage.setItem("authToken", action.user.authToken)
      window.localStorage.setItem("__authToken", action.user.authToken)
      window.localStorage.setItem("authEmail", action.user.email)
      window.localStorage.setItem("__authEmail", action.user.email)
      return state.merge(
        fromJS({
          token: action.user.authToken,
          email: action.user.email
        })
      )
    }
    case LOGOUT: {
      window.localStorage.setItem("authToken", "")
      window.localStorage.setItem("__authToken", "")
      window.localStorage.setItem("authEmail", "")
      window.localStorage.setItem("__authEmail", "")
      return state.merge(
        fromJS({
          token: "",
          email: ""
        })
      )
    }
    default:
      return state
  }
}
