/*
 *
 * Sidebar reducer
 *
 */

import React from "react"
import { fromJS } from "immutable"
import HomeIcon from "@material-ui/icons/Home"
import ListIcon from "@material-ui/icons/List"
import RestaurantMenu from "@material-ui/icons/RestaurantMenu"
import AccountBoxIcon from "@material-ui/icons/AccountBox"
import MoneyIcon from "@material-ui/icons/Money"
import CreditCardIcon from "@material-ui/icons/CreditCard"
import HistoryIcon from "@material-ui/icons/History"
import SettingsIcon from "@material-ui/icons/SettingsOutlined"
import ReportsIcon from "@material-ui/icons/Assessment"

export const initialState = fromJS({
  pages: [
    {
      name: "Log In",
      link: "/login",
      noSession: true,
      matches: [/^\/login$/],
      icon: <AccountBoxIcon />
    },
    {
      name: "Home",
      link: "/",
      matches: [/^\/$/],
      icon: <HomeIcon />
    },
    {
      name: "Catering Preferences",
      link: "/catering-preferences",
      matches: [/^\/catering-preferences$/],
      icon: <SettingsIcon />
    },
    {
      name: "Payment Setup",
      link: "/payment",
      matches: [/^\/payment$/],
      icon: <CreditCardIcon />
    },
    {
      name: "Truck Invoices",
      link: "/sales-guarantee-invoices",
      matches: [/^\/sales-guarantee-invoices.*$/],
      icon: <MoneyIcon />
    },
    {
      name: "Catering History",
      link: "/catering-payment-history",
      matches: [/^\/catering-payment-history.*$/],
      icon: <HistoryIcon />
    },
    {
      name: "Members",
      link: "/members",
      matches: [/^\/members.*$/],
      icon: <ListIcon />
    },
    {
      name: "My Lots",
      link: "/lots",
      matches: [/^\/lots.*$/],
      icon: <RestaurantMenu />
    },
    {
      name: "Reports",
      link: "/reports",
      matches: [/^\/reports.*$/],
      icon: <ReportsIcon />
    }
  ]
})

function navbarReducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state
  }
}

export default navbarReducer
