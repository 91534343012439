import { USER_LOGGED_IN, LOGOUT } from "./constants"

export const userLoggedIn = (user) => ({
  type: USER_LOGGED_IN,
  user
})

export const logout = () => ({
  type: LOGOUT
})
