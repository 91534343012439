import { graphql } from "@apollo/client/react/hoc"
import gql from "graphql-tag"

const QUERY = gql`
  query currentCorporateUserQuery {
    currentCorporateUser {
      id
      name
      clientCompany {
        id
      }
    }
  }
`

const withUser = graphql(QUERY, {
  options: () => ({
    fetchPolicy: "cache"
  }),
  props: ({ data: { loading, currentCorporateUser } }) => ({
    userLoading: loading,
    user: currentCorporateUser
  })
})

export default withUser
