/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import Rollbar from "common/utils/Rollbar"

Rollbar.init()

// Import all the third party stuff
import React from "react"
import "sanitize.css/sanitize.css"
// eslint-disable-next-line
import formActionSaga from "common/pkg/redux-form-saga"
import confirmDialog from "corporate/utils/confirmDialog"
import requestValue from "corporate/utils/requestValue"

// Import root app
import App from "corporate/containers/App"

// Load the favicon, the manifest.json file and the .htaccess file
/* eslint-disable import/no-unresolved, import/extensions, import/no-webpack-loader-syntax */
import "!file-loader?name=[name].[ext]!./corporate.manifest"
import "!file-loader?name=[name].[ext]!images/favicon.ico"
import "!file-loader?name=[name].[ext]!images/favicon-48.png"
import "!file-loader?name=[name].[ext]!images/favicon-96.png"
import "!file-loader?name=[name].[ext]!images/favicon-144.png"
import "!file-loader?name=[name].[ext]!images/favicon-192.png"
import "!file-loader?name=[name].[ext]!images/logo512.png"
/* eslint-enable import/no-unresolved, import/extensions, import/no-webpack-loader-syntax */

// Import CSS reset and Global Styles
import theme, { muiTheme, jss } from "./theme"
import configureStore from "./configureStore"
import ConnectedApp from "common/ConnectedApp"

export default function CorporateApp() {
  return (
    <ConnectedApp
      authTokenName={"X-CorporateUser-Token"}
      authEmailName={"X-CorporateUser-Email"}
      configureStore={configureStore}
      jss={jss}
      theme={theme}
      muiTheme={muiTheme}
      confirmDialog={confirmDialog}
      requestValue={requestValue}
    >
      <App />
    </ConnectedApp>
  )
}
