/**
 *
 * App.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 * NOTE: while this component should technically be a stateless functional
 * component (SFC), hot reloading does not currently support SFCs. If hot
 * reloading is not a necessity for you then you can refactor it and remove
 * the linting exception.
 */

import React from "react"
import PropTypes from "prop-types"
import { Switch, Route, withRouter, Redirect } from "react-router-dom"
import CssBaseline from "@material-ui/core/CssBaseline"
import { connect } from "react-redux"
import { compose } from "redux"
import HomePage from "corporate/containers/HomePage/Loadable"
import MembersPage from "corporate/containers/MembersPage/Loadable"
import NewMemberPage from "corporate/containers/NewMemberPage/Loadable"
import CateringPreferencesPage from "corporate/containers/CateringPreferencesPage/Loadable"
import NotFoundPage from "corporate/containers/NotFoundPage/Loadable"
import LoginPage from "corporate/containers/LoginPage/Loadable"
import PaymentSetupPage from "corporate/containers/PaymentSetupPage/Loadable"
import SalesGuaranteeInvoicesPage from "corporate/containers/SalesGuaranteeInvoicesPage/Loadable"
import NewClientCompanyCardPage from "corporate/containers/NewClientCompanyCardPage/Loadable"
import PaymentHistoryPage from "corporate/containers/PaymentHistoryPage/Loadable"
import PaymentHistoryDayPage from "corporate/containers/PaymentHistoryDayPage/Loadable"
import ErrorBoundary from "common/components/ErrorBoundary/index"
import Navbar from "corporate/components/Navbar"
import NotificationStack from "corporate/components/NotificationStack"
import BlockUIConnected from "corporate/components/BlockUIConnected"
import withUser from "corporate/queries/withUser"
import PageLoader from "common/components/PageLoader"
import LotsPage from "corporate/containers/LotsPage/Loadable"
import MemberPage from "corporate/containers/MemberPage/Loadable"
import BulkMemberCreatePage from "corporate/containers/BulkMemberCreatePage/Loadable"
import ReportsPage from "corporate/containers/ReportsPage/Loadable"
import ReportPage from "corporate/containers/ReportPage/Loadable"
import ForgotPasswordPage from "corporate/containers/ForgotPasswordPage/Loadable"
import ResetPasswordPage from "corporate/containers/ResetPasswordPage/Loadable"
import { UserContext } from "corporate/components/withExistingUser"

class App extends React.PureComponent {
  render() {
    const { userLoading, user } = this.props
    if (userLoading) return <PageLoader />
    const routes = user ? (
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route
          exact
          path="/catering-preferences"
          component={CateringPreferencesPage}
        />
        <Route
          exact
          path="/catering-payment-history"
          component={PaymentHistoryPage}
        />
        <Route
          exact
          path="/sales-guarantee-invoices"
          component={SalesGuaranteeInvoicesPage}
        />
        <Route
          exact
          path="/catering-payment-history/:date"
          component={PaymentHistoryDayPage}
        />
        <Route exact path="/payment" component={PaymentSetupPage} />
        <Route
          exact
          path="/payment/new-card"
          component={NewClientCompanyCardPage}
        />
        <Route exact path="/login" component={LoginPage} />
        <Redirect to="/" path="/sign-in" />
        <Route exact path="/lots" component={LotsPage} />
        <Route exact path="/members" component={MembersPage} />
        <Route exact path="/members/new-member" component={NewMemberPage} />
        <Route
          exact
          path="/members/bulk-create"
          component={BulkMemberCreatePage}
        />
        <Route exact path="/members/:id" component={MemberPage} />
        <Route exact path="/reports" component={ReportsPage} />
        <Route exact path="/reports/:reportUrl" component={ReportPage} />
        <Route exact path="/forgot-password" component={ForgotPasswordPage} />
        <Route exact path="/reset-password" component={ResetPasswordPage} />
        <Route component={NotFoundPage} />
      </Switch>
    ) : (
      <Switch>
        <Route exact path="/login" component={LoginPage} />
        <Route exact path="/forgot-password" component={ForgotPasswordPage} />
        <Route exact path="/reset-password" component={ResetPasswordPage} />
        <Redirect to="/" path="/sign-in" />
        <Redirect to="/login" />
      </Switch>
    )
    return (
      <main>
        <UserContext.Provider value={user}>
          <CssBaseline />
          <Navbar>
            <ErrorBoundary message="Error Displaying the page. Try reloading the browser.">
              {routes}
            </ErrorBoundary>
          </Navbar>
          <BlockUIConnected />
          <NotificationStack />
        </UserContext.Provider>
      </main>
    )
  }
}

App.propTypes = {
  user: PropTypes.object
}

export default compose(withRouter, connect(), withUser)(App)
