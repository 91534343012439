import { connect } from "react-redux"
import { compose } from "redux"
import injectReducer from "corporate/utils/injectReducer"
import {
  BlockUIConnected,
  mapStateToProps,
  reducer
} from "common/components/BlockUIConnected"

const withConnect = connect(mapStateToProps)
const withReducer = injectReducer({
  key: "BlockUIConnected",
  reducer
})

export default compose(withReducer, withConnect)(BlockUIConnected)
